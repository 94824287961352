import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";


import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Portfolio from './components/portfolio';
import PortfolioV2 from './components/portfolio-v2';
import PortfolioDetails from './components/portfolio-details';
import Team from './components/team';
import TeamDetails from './components/team-details';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error  from './components/404';

import BlogGrid from './components/blog-grid';
import Blog from './components/blog';

import BlogDetails from './components/blog-details';
import Contact from './components/contact';
// import Home_V5 from './components/home-v6';
// import Home_V7 from './components/home-v7';
// import Home_V3 from './components/home-v3';
import HomeMain from './components/homemain';
import TermsCondition from './components/terms-condition';
import PrivacyPolicy from './components/privacy';
import Carrier from './components/carrier';
import Siding_main from './components/siding-main';
import Roofing_main from './components/roofing-main';
import Concrete_main from './components/concrete-main';
// import Siding from './components/section-components/siding';
// import Roofing from './components/section-components/roofing';


class Root extends Component {
    render() {
        return(
                <HashRouter basename="/">
	                <Switch>
                       
                        <Route exact path="/" component={HomeMain} />
                        <Route path="/about" component={About} />
                        <Route path="#" component={Service} />
                        <Route path="/siding" component={Siding_main} />
                        <Route path="/roofing" component={Roofing_main} />
                        <Route path="/concrete" component={Concrete_main} />
                        <Route path="/service-details" component={ ServiceDetails } />
                        <Route path="/portfolio" component={ Portfolio } />
                        <Route path="/portfolio-v2" component={ PortfolioV2 } />
                        <Route path="/portfolio-details" component={ PortfolioDetails } />
                        <Route path="/team" component={ Team } />
                        <Route path="/team-details" component={ TeamDetails } />
                        <Route path="/faq" component={ Faq } />
                        
                        <Route path="/coming-soon" component={ ComingSoon } />
                        <Route path="/404" component={ Error } />
                        <Route path="/term-condition" component={ TermsCondition } />
                        <Route path="/privacy-policy" component={ PrivacyPolicy } />
                        
                        {/* blog */}
                        <Route path="/blog-grid" component={ BlogGrid } />
                        <Route path="/blog" component={ Blog } />
                        <Route path="/blog-details" component={ BlogDetails } />
                        <Route path="/contact" component={ Contact } />
                        <Route path="/carrier" component={ Carrier } />
	                </Switch>
                </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
