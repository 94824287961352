import React from 'react';
import NavbarMain from './global-components/navbarmain';
import Banner from './section-components/banner-v3';
// import Navbar from './global-components/navbar-v3';
// import Banner from './section-components/banner-v3';
import Features from './section-components/features-v1';
import Testimonial from './section-components/testimonial-v1';
import Footer from './global-components/footermain';
import Neighbour from './section-components/neighbour';
import Cateogory from './section-components/category-v2';
import Apartment from './section-components/apartment-main';
import About from './section-components/aboutmain';
// import ProSlider from './section-components/product-slider-v1';

const HomeMain = () => {
    return <div>
        <NavbarMain />
        <Banner />/
        {/* <Navbar />
        <Banner /> */}
        <About />
        <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---"/>
        {/* <ProSlider /> */}
        {/* <Apartment /> */}
        <Neighbour />
        <Cateogory />
        {/* <Testimonial /> */}
        <Footer />
    </div>
}

export default HomeMain