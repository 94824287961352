import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Concrete extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">					
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Your trusted partner for Concrete Services in Edmonton- Malwa Siding got you covered!</h6>
							<h1 className="section-title">Providing high-quality  concrete services in Edmonton<span>.</span></h1>
							<p>Providing high-quality concrete services in Edmonton is the commitment of Malwa Siding Ltd. We specialize in meeting unique residential and commercial project needs. Right from fully-fledged large-scale projects, we’ve got you covered. Making sure that the products and services we deliver are sturdy, top-notch, quality-driven, and customer-centric is our supreme objective. </p>
							</div>
							
							{/* <div className="btn-wrapper animated">
							<Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
							</div> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl+"assets/img/others/pexels-rquiros-2219024.jpg"} alt="Roofing Image" />
						</div>
					</div>
					<div className="col-lg-12 align-self-center">
						<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Why Choose Us for Concrete Services in Edmonton?" </p>
						</div>
						<div>Our excellence is a direct result of our commitment to professionalism and efficiency. Our customer-centric, client-first approach has built a reputation for us that attracts customer satisfaction as a by-product. Our long-withstanding commitment to precision, using the best techniques for installation and repair, and professionalism is a testimony of what makes us the best service partner for concrete in Edmonton. <br/><br/>
Our team is skilled in all aspects associated with concrete services, including concrete installation. Across and through the Edmonton area, we are a trusted choice and partner for customers. <br/><br/>
Are you looking for concrete installation near me or need a full concrete replacement near me? Look no further! <br/><br/>
Our services such as Driveway, Sidewalk, concrete patio are designed in a way that they remain specific and tailored to the individual needs and client’s preferences. We aim to deliver the best results with efficiency and streamline the processes that keep projects within budget and on time. </div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Comprehensive Concrete Services</h6>
						<div>
						Our company Malwa Siding Ltd offers a complete scope of concrete services in Edmonton. Our services range from simple driveway repair to entire foundation replacement and all jobs are done with an emphasis on strength, quality and customer satisfaction.
						</div>
						<h4 className="section-title mt-30">Concrete Installation Services<span>.</span></h4>
						<div>
						Whenever concrete is used, it is yet to be hardened and after some years it is bound to have some cracks, chips or any other sign of wear. Our concrete installation services help bring back the functionality and aesthetic value of your concrete while at the same time reconstructing it. Our materials are first-rate, and our installationmethods are foolproof to ensure you receive a surface that complements your concrete well.<br/><br/>

						For those that are looking for concrete installation near me, Malwa Siding Ltd offers the best services that can help you get an extended service of concrete. Our team will evaluate each project to discuss individual projects that will require immediate installation and how our service will address your concerns whether they are imposed by weather damage, the age of the structure, or normal usage. 
						</div>

						<h4 className="section-title mt-30">Building Durable Concrete Surfaces<span>.</span></h4>
						<div>
						If a concrete surface needs new presence our concrete installation services are the best solution. Ideal for making new appearance to existing or for new constructions, we offer quality concrete surfaces in your desired style. We provide a number of services that include driveways, pathways, patios, and the foundational frameworks of any project done here are execited with high precision. <br/><br/>
						We incorporates the process of demolition of the old and damaged floor, preparing the ground and employing highly effective installation methods to enhance the quality of the floor. <br/><br/>
						Quality subflooring accessories promote easy smooth and leveled surfaces which compliments the functionality and beauty of the whole flooring system. We make the most of our experience to achieve a magnificent concrete strength to complement your investment for the place.<br/><br/>
						We focus on quick concrete renovation with work ranging from driveways and pathways, patios, and basic frameworks. It involves pulling out the old, damaged concrete and then preparing the ground for new concrete that will be strong and there to stay. To ensure that the subflooring is smooth and levelled we use high-quality subflooring accessories and installation methods that turn around your building’s appearance and functionality for the better.

						</div>

						<h4 className="section-title mt-30">Different Concrete Services Offered<span>.</span></h4>
						<div>
						Concrete has several forms and each type is used in construction operations in some manner. At Malwa Siding Ltd., we are always able to advise you so that you make the right decision in terms of concrete to use depending on the project you wish to undertake. Some of our specialized services include:

						</div>
						
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Driveways</h6>
						<div>
						Properly installed concrete driveways can accommodate high traffic and withstand the test of the weather conditions in Edmonton giving you the longevity and style you need.
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Walkways and Sidewalks</h6>
						<div>
						You can make your property more functional and aesthetically appealing by hiring our professional concrete specialists to install your walkways and sidewalks.

						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Patios</h6>
						<div>
						Upgrade your home exterior and design a unique concrete patio using our concrete solutions in various styles and patterns.
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Foundations</h6>
						<div>
						It is always very important that any structure be well founded, our foundation concrete services are very solid and very well laid.
						</div>

						<h6 className="section-title mt-30">Decorative Concrete<span>.</span></h6>
						<div>
						Make your property stand out with our decorative concrete, the options of which include the following: Stamped concrete, stained concrete, exposed aggregate concrete.
						<br/> <br/>
						<h6 className="section-title mt-30">Providing services for Edmonton and the rest of Alberta.<span>.</span></h6>
						<div>
							Being one of the biggest suppliers of concrete in Edmonton, we realize the unique problems that buildings have in this area. Our climate can wear out concrete surfaces which is why we always ensure we use the best quality of material that can stand our climatic conditions in Edmonton. It is our pleasure to offer specialized services for homes and businesses in Edmonton and beyond – our works are designed to withstand extreme temperatures including cold seasons and wet seasons. <br/><br/>
							For any concrete repair near me or a concrete replacement near me, we are ready to help with client consultation and excellent service delivery. It gives us great pleasure to operate within the Edmonton area; helping provide valuable concrete solutions for every project whether it be for safety, functionality, or aesthetics.
						</div>
						<h4 className="section-title mt-30">Why Quality Concrete Matters?<span>.</span></h4>
						<div>
						Concrete is crucial in any property development as it gives the building’s structure and esthetic value. You will be pleased to know here at Malwa Siding Ltd., we always stick to the best quality and standard practice while providing the best concrete work possible. G2 Concrete Cutting has learned that it can cost much more to repair or replace structures made with substandard concrete and therefore it is pertinent to use sound concrete services from professionals. <br/><br/>
						That is why, choosing Malwa Siding Ltd. as your concrete repair or concrete replacement provider, you get a team of specialists that appreciate quality, professionalism, and clients. Knowing that no two projects are alike, we apply the required attention to each service we provide.

						</div>

						<h4 className="section-title mt-30">Ready to Get Started?<span>.</span></h4>
						<div>
						If you need a reliable contractor who provides concrete in Edmonton, Malwa Siding Ltd. is the contractor for you. 
						<br/><br/>
							Our team is always willing to assist you in revamping your property through the best concrete services all geared towards ensuring that you get your heart’s desire.<br/><br/>
							From small and specific concrete fixing to an extensive solution or newly constructed concrete installation project, we provide you with superior quality and durable results and services.

 							<b>Choose us now and make an appointment to know more about how Malwa Siding Ltd. can improve your space with our excellent work that is in concrete services.</b>

						</div>


						</div>


					</div>
				</div>
				</div>
			</div>
        }
}

export default Concrete