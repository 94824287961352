import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/others/13.jpg"} alt="About Us Image" />
						{/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
							<div className="ltn__video-img ltn__animation-pulse1">
								<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
								<i className="fa fa-play" />
								</a>
							</div>
						</div> */}
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
						<h1 className="section-title">Malwa Siding Ltd.- Your trusted siding service partner in Edmonton<span>.</span></h1>
						<p>Malwa Siding Ltd. Is your trusted partner in facilitating high-quality, durable siding in Edmonton. Our team of experts has got you covered for all siding solutions whether you’re looking for a replacement, new installation, or exterior, Installation. Get a new look to existing or new construction, new siding residential, new siding commercial service. Our experts understand the importance of siding in protecting and safeguarding your property. Whether the question is about durability or appearance, exterior, installation, new look to existing or new construction, new siding Residential, new Siding Commercial, and whatnot is what can keep you covered. </p>
						</div>
						{/* <ul className="ltn__list-item-half clearfix">
						<li>
							<i className="flaticon-home-2" />
							Smart Home Design
						</li>
						<li>
							<i className="flaticon-mountain" />
							Beautiful Scene Around
						</li>
						<li>
							<i className="flaticon-heart" />
							Exceptional Lifestyle
						</li>
						<li>
							<i className="flaticon-secure" />
							Complete 24/7 Security
						</li>
						</ul> */}
						<div className="ltn__callout bg-overlay-theme-05  mt-30">
						<p>"Siding for your commercial and your residentials structures <br />
						No matter what you’re looking for- siding for your office or your home" </p>
						</div>
						{/* <div className="btn-wrapper animated">
						<Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
						</div> */}
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4