import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Why Choose Us</h6>
			          <h1 className="section-title">Why Choose Malwa Siding Ltd.?</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/21.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Superior Quality</Link></h3>
			            <p>We use the finest quality and materials in the industry. Our Lap Siding, Siding Panels, and Siding Exterior, roofing Exterior, Concrete Flat Work are crafted to give your project that missing element- the soul of your home or office. The long-standing beauty and durability of our products make us unique.</p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/22.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Customer-Centered</Link></h3>
			            <p>Our focus and commitment towards customer satisfaction and customer experience makes us a preferred choice among others. From our first consultation to the final product installation, we stand every step of the way with you. We listen, understand, and execute your vision to the highest standards.</p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Affordable Pricing</Link></h3>
			            <p><b>Malwa Siding Ltd.</b> Believes that every customer with their dream project deserves a chance to make it happen without feeling like they have made a hole in their pocket. By optimizing labor wages and streamlining costs, we strive to offer quality siding without overpaying. By streamlining our costs.</p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>

				  <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Wide Range of Styles</Link></h3>
			            <p>With choices like Lap Siding, Siding Panels, and Siding Exterior, roofing Exterior, Concrete Flat Work, you’ll have no trouble finding the right siding for your office or home. Each style at our company is unique, and crafted with durability, excellence, and quality in mind- making sure that your property’s appearance stays stunning.</p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Fast and Reliable</Link></h3>
			            <p>We understand that your time is valuable. Our team is dedicated to timely, efficient, and thorough installation processes, so you can start enjoying your new siding sooner than you might expect. Our commitment to speed and quality ensures that your project is completed on time, without any delays or setbacks.</p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          {/* <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div> */}
			          <div className="ltn__feature-info">
			            <h3><Link to="/contact">Deliver on Time</Link></h3>
			            <p><b>Deliver on time</b> Malwa Siding Ltd. is your trusted partner specializing in premium siding solutions across North America. As the fastest-growing siding company, we have gathered extensive experience from working with a range of successful siding companies to elevate customer service. </p>
			            <Link className="ltn__service-btn" to="/contact">Get more details <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1