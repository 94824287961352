import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Config from '../../config/default.json';

const CarrierForm = () => {
	const [isSaved, setisSaved] = useState(false);
    const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
    const [resError, setResError] = useState({});
	const [files, setFiles]               = useState([]);
	const [fileError, setFileError]       = useState(false);
    const [btnTxt, setBtnTxt] = useState('Send Message')

	const handleFileChange = (e) => {
		if (e.target.files && e.target.files[0].size <= 3e6) {
			setFiles(e.target.files[0])
			setFileError(false)         
		}else{
			setFileError(true)                          
			e.target.value = ""
			return false
		}  
    };

	const handleCarrier = async (data) => {
		try { 
		  //  e.preventDefault();
		  const formData = new FormData();
		  formData.append('name', data.name);
		  formData.append('email', data.email);
		  formData.append('mobile', data.mobile);
		  formData.append('description', data.description);
		  if (data.file[0]) {
			formData.append('file', data.file[0]);
		  }

		  setBtnTxt('Sending...');

			try {
				
					const Res = await axios.post(`${Config.api_host}contact/store-carrier`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					});
					
					if(Res.data.success){
						setisSaved(true);
						reset({name:'', email:'', mobile:'', attachment:'', description:'' })
						setBtnTxt('Send Message');
					}else{
						if(Res.data.email!=undefined){
							setResError({email:Res.data.email[0]})
						}
						if(Res.data.password!=undefined){
							setResError({password:Res.data.password[0]})
						}
						if(Res.data.error){
							setResError({error:Res.data.error})
						} 
						setBtnTxt('Send Message 9');
					}


			} catch (error) {
				console.error('There was an error!', error);
			}
	
			setTimeout(() => {
			  setisSaved(false);
			}, 5000);    
			
		} catch (error) {
		  console.error(error.message);
		}
	};


	return (
        <>      
			<div className="ltn__contact-message-area pt-110--- pb-90"> 
					<div className="container">
					<div className="row">
						<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Carrier</h6>
							<h1 className="section-title">Make Your Carrier with Us</h1>
						</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
						<div className="ltn__form-box contact-form-box box-shadow white-bg">
							<h4 className="title-2">Carrier, Upload your resume</h4>
							<form className="row" onSubmit={handleSubmit(handleCarrier)} encType="multipart/form-data" >
							<div className="row">
								<div className="col-md-6">
								<div className="input-item input-item-name ltn__custom-icon">
									<input type="text" name="name" placeholder="Enter your name" {...register("name", { required: 'Field is required' })} />
									{errors.name && <span className="invalid" >Name is required.</span>}
								</div>
								</div>
								<div className="col-md-6">
								<div className="input-item input-item-email ltn__custom-icon">
									<input type="email" className="cs-form_field" placeholder="Enter email address" {...register("email", { 	required: true, pattern: {
										value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
										message: 'Invalid email address',
										}, })} />

										{errors.email?.type==="required" && <span className="invalid" >Email is required.</span>}
										{errors.email?.type === "pattern" && (
											<span className="invalid" >Invalid email</span>
										)}
								</div>
								</div>
								
								<div className="col-md-6">
									<div className="input-item input-item-phone">
										<input type="file" multiple data-min_length="1" accept="image/png, image/jpeg, image/gif, .doc,.docx,application/pdf" className="upload__inputfile" 
											{...register("file", {
												required: false,
											})} 
											onChange={handleFileChange}
										/>
										{fileError && <span className="invalid" >File size should be less than 3 MB.</span>}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-phone ltn__custom-icon">
										<input type="text" name="mobile" placeholder="Enter phone number" {...register("mobile", { required: true,
                                                              //  pattern: {
                                                              //     value: /^[0-9]{0,9}$/,
                                                              //     message: 'Invalid email address',
                                                              // } 
                                                              })}  />									
                      						{errors.mobile?.type === "required" && <span className="invalid" >Mobile is required.</span>}
									</div>
								</div>
							</div>
							<div className="input-item input-item-textarea ltn__custom-icon">
								<textarea name="description" placeholder="Enter message" {...register("description")} cols="30"
                        		rows="7" />
							</div>
							{/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p> */}
							<div className="btn-wrapper mt-0">								
								<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
								<span>{btnTxt}</span>
								{/* <Icon icon="bi:arrow-right" /> */}
							</button>
							</div>							
								{ (isSaved)?
									(<div className="col-sm-12">
										<span>Your Form Send Successfully.</span>
									</div>) : "" 
								}
							</form>
						</div>
						</div>
					</div>
					</div>
				</div>
		</>
    )
}

export default CarrierForm