import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import BlogRightSidebar from './blog-components/blog-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const BlogRightSidebarPage = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Blog" subheader="Blog" />
        <BlogRightSidebar />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BlogRightSidebarPage

