import React from 'react';
// import Navbar from './global-components/navbar';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import AboutV5 from './section-components/about-v5';
import ServiceV1 from './section-components/service-main';
import Footer from './global-components/footermain';

const Service_V1 = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="What We Do" subheader="Service" />
        {/* <AboutV5 /> */}
        <ServiceV1 />
        <Footer />
    </div>
}
export default Service_V1

