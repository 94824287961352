import Config from '../config/default.json';

// Return the user data from local storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    return userStr ? JSON.parse(userStr) : null;
};

// Return the token from local storage
export const getToken = () => localStorage.getItem('user-token') ?? null;

// Remove the token and user from local storage
export const removeUserSession = () => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user');
};

// Set the token and user in local storage
export const setUserSession = (token, user) => {
    localStorage.setItem('user-token', token);
    localStorage.setItem('user', JSON.stringify(user));
};

// Login function
const login = async (credentials) => {
    const API_URL = `${Config.api_host}auth/login`;
    try {
        const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });

        const data = await response.json();
        return data; // Assume the API returns { user, token }
    } catch (error) {
        console.error('Login error:', error);
    }
};

export { login };

// Post request without authentication
export const PostNoAuthRequest = async (path, params) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };
    
    try {
        const response = await fetch(`${Config.api_host}${path}`, requestOptions);
        const data = await response.json();
        if (!data.success) {
            const error = data.message ?? data.status;
            console.error('Error:', error);
        }
        return data;
    } catch (error) {
        console.error('Request error:', error);
    }
};


export const PostRequestWithAttachment = async (path, params) => {
    const response = await fetch(`${Config.api_host}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: params
      });
      const data = await response.json();
      console.log("Fdsfsd ", data);
    // const requestOptions = {
    //     // mode: 'no-cors',
    //     method: 'POST',
    //     headers: { 
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         "Content-Type": "multipart/form-data" ,
    //     },
    //     body: params
    // };
    
    // try {
    //     const response = await fetch(`${Config.api_host}${path}`, requestOptions);
    //     const data = await response.json();
    //     if (!data.success) {
    //         const error = data.message ?? data.status;
    //         console.error('Error:', error);
    //     }
    //     return data;
    // } catch (error) {
    //     console.error('Request error:', error);
    // }
};

// Post request with authentication
export const PostAuthRequest = async (path, params) => {
    const token = getToken();
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Credentials': 'true'
        },
        body: JSON.stringify(params)
    };

    try {
        const response = await fetch(`${Config.api_host}${path}`, requestOptions);
        const data = await response.json();
        if (!data.success) {
            const error = data.message ?? data.status;
            console.error('Error:', error);
        }
        return data;
    } catch (error) {
        console.error('Request error:', error);
    }
};

// Post request for file upload
export const PostUploadFiles = async (path, params) => {
    const token = getToken();
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': `Bearer ${token}`
        },
        body: params
    };

    try {
        const response = await fetch(`${Config.api_host}${path}`, requestOptions);
        const data = await response.json();
        if (!data.success) {
            const error = data.message ?? data.status;
            console.error('Error:', error);
        }
        return data;
    } catch (error) {
        console.error('Upload error:', error);
    }
};

// Get records function
export const getRecords = async (path) => {
    const token = getToken();
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await fetch(`${Config.api_host}${path}`, requestOptions);
        const data = await response.json();
        if (!data.success) {
            const error = data.message ?? data.status;
            console.error('Error:', error);
        }
        return data;
    } catch (error) {
        console.error('Request error:', error);
    }
};

// Add or remove body classes
export const addBodyClass = (className) => document.body.classList.add(className);
export const removeBodyClass = (className) => document.body.classList.remove(className);