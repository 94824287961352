import React from 'react';
// import Navbar from './global-components/navbar';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import AboutV5 from './section-components/about-v5';
import Roofing from './section-components/roofing';
import Footer from './global-components/footermain';

const Roofing_main = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Edmonton Roofing Replacement , Roof Repair, New Roof for Commercial and Residentails" subheader="Service" />
        {/* <AboutV5 /> */}
        <Roofing />
        <Footer />
    </div>
}
export default Roofing_main

