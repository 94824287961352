import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import ServiceDetails from './section-components/service-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const Service_Details = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Service Details" subheader="Property Management" />
        <ServiceDetails />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Service_Details

