import React from 'react';
// import Navbar from './global-components/navbar';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import AboutV5 from './section-components/about-v5';
import Concrete from './section-components/concrete';
import Footer from './global-components/footermain';

const Concrete_main = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Expert Concrete Services in Edmonton for All Your Property Needs" subheader="Service" />
        {/* <AboutV5 /> */}
        <Concrete />
        <Footer />
    </div>
}
export default Concrete_main

