import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutMain extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-115 go-top">
				<div className="ltn__about-us-area go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-5 align-self-center">
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl+"assets/img/service/11.jpg"} alt="Image" />
					</div>
					</div>
					<div className="col-lg-7 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
						<h1 className="section-title">Get siding for your office or home<span>.</span></h1>
						<p>At Malwa Siding Ltd., we believe in creating homes and offices that stand the test of time and reflect personal taste and character. We believe in driving excellence through continuous improvement and by providing exceptional siding services in Edmonton and beyond. We combine our years of excellence and expertise with innovative solutions that go beyond just catering to your needs- because we believe in delivering exceptional customer experiences.</p>
						</div>
						<div className="about-us-info-wrap-inner about-us-info-devide---">
						<p>No matter what you're looking for- siding for your office or your home, our variety of options bring a vision to your dream project. In our product portfolio, you’ll find Lap Siding, Siding Panels, and Siding Exterior, roofing Exterior, Concrete Flat Work, making it easier for our customers to select an ideal style and material for their dream property. With Malwa Siding Ltd., you're not just investing in a renovation—you’re partnering with a siding company dedicated to making your property look as good as new.</p>
						</div>
						<div className="btn-wrapper animated">
						<Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>	
			</div>
        }
}

export default AboutMain