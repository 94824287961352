import React from 'react';
import { Link } from 'react-router-dom';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footermain';

const TermsCondition = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return  <>
    <NavbarMain />
    <PageHeader headertitle="Terms & Conditions" subheader="Terms & Conditions" />    
    <div className="ltn__team-details-area mb-120">
        <div className="container">
        <div className="row">
            
            <div className="col-lg-12">
            <div className="ltn__team-details-member-info-details">
            Terms & Conditions
            </div>
            </div>
        </div>
        </div>
    </div>
        <Footer />
    </>
}

export default TermsCondition

