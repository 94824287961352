import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import BlogGrid from './blog-components/blog-grid';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const BlogGridPage = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Blog Grid" />
        <BlogGrid />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BlogGridPage

