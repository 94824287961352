import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Siding extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">	
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl+"assets/img/others/13.jpg"} alt="Roofing Image" />
						</div>
					</div>				
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Looking for a trust to mend your siding rust? Look no further! We are here to fix all types of siding in Edmonton. </h6>
							<h1 className="section-title">Malwa Siding Ltd.- Your trusted siding service partner in Edmonton<span>.</span></h1>
							<p>Malwa Siding Ltd. Is your trusted partner in facilitating high-quality, durable siding in Edmonton. Our team of experts has got you covered for all siding solutions whether you’re looking for a replacement, new installation, or exterior, Installation. Get a new look to existing or new construction, new siding residential, new siding commercial service. Our experts understand the importance of siding in protecting and safeguarding your property. Whether the question is about durability or appearance, exterior, installation, new look to existing or new construction, new siding Residential, new Siding Commercial, and whatnot is what can keep you covered<br/><br/>
							That is why, at Malwa Siding Ltd., our commitment remains to deliver a top-notch experience to our customers to build everlasting relationships. The precision-focused service is crafted to meet the growing demand for Edmonton siding services and the unique needs of our clients in the locality and beyond.
							</p>
							</div>
							
						</div>
					</div>
					
					<div className="col-lg-12 align-self-center">
						<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Why Choose Malwa Siding Ltd. for all kinds of sidingservices?" </p>
						</div>
						<div>We understand that no two homes can look alike. Each home has its own story- each one standing its challenges and conditions- even in the same geographical scenarios. We understand why and how each project requires a unique problem-solving skill with a personalized approach. With years of experience and expertise in new look to existing or new construction, new siding residential, new siding commercial service, and installations our team makes it easy to deliver a project.<br/><br/> 
						By choosing us, you decide to partner with a vendor that is committed to delivering value and protecting your property.  Edmonton’s diverse climate makes it even more essential to have a partner that understands siding issues in and out. Whether you’re seeking new look to existing or new construction, new siding residential, new siding commercial or a complete siding service, we can consult you every step of the way. Ensuring that your projects get delivered on time is also on us. There are no sad experiences with us- just long withstanding, commitment, and assurance of great deliverables! 
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">A Complete Suite of Siding Services</h6>
						<div>
						Whether there is a requirement for full new look to existing or new construction, new siding residential, new siding commercial, our siding services are a complete holistic suite of solutions that come with professional precision. We used advanced techniques and methodologies to inspect, provide detailed consultation, and understand your goals and preferences. 
						<br/><br/> 
						After having gone through this process, we provide a complete roadmap of new look to existing or new construction, new siding residential, new siding commercial service or a complete siding services near you, if your project is about replacement. If you’re looking for the siding services for the first time, we recommend the best siding options and solutions depending upon your budget and property condition.
						</div>
						<h4 className="section-title mt-30">Outdoor Cladding Installation Services<span>.</span></h4>
						<div>
						Your house needs a strong and classy exterior roof. If it is designing new siding only standard residential project or even if it is new siding only commercial projects, our installation services also redesign new constructions. Our specialization includes improving the looks of your building and the use of high quality material to ensure they are replaced rarely. They work diligently to make sure the process is professional throughout including stripping off the old sidings and fixing a new sophisticated appearance that can boost the safety and the value in equal proportion. Consult for choices such as vinyl, cement or wood to meet your various preferences.
						<br/><br/> 
						Replacing exterior sidings is an area of specialty for many contractors nowadays.
						<br/><br/> 
						If your property’s siding requires more than just a paint job or repaired, then our exterior siding replacement options are what you need. Exclusively suitable for home or commercial restructuring or reconstruction, our services entail stripping off worn-out sidings and replacing them with the superior material. With our team, your building façade and protection is improved not only in strength but also in the aesthetic sense. Select from a broad contingent of products and you don’t have to worry how your building will be revamped because we will ensure your property gets a face lift.
						</div>

						<h4 className="section-title mt-30">Vinyl Siding Services<span>.</span></h4>
						<div>
						Among the most popular choices of modern homeowners are the vinyl siding service for its aesthetic appeal, different pattern options, and colors. Also, Vinyl siding is the most low-maintenance service you could ask for. <br/><br/>
						At Malwa Siding Ltd., we specialize in vinyl siding service near me and installation, helping you install the most versatile solution. We make it easier for our clients to come and opt for the options that come in a large variety. Vinyl offers a great deal of flexibility and durability. It is thus possible to achieve a finished look that not only gives long-lasting protection to your home but also a wonderful aesthetic appeal.
						<br/><br/>
						Our service partners address complex issues like warping, cracks, and fading- providing you with the most cost-effective solutions, giving your home a fresh and vibrant look.
						</div>

						<h4 className="section-title mt-30">Quality Materials and Expert Craftsmanship<span>.</span></h4>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Vinyl Siding</h6>
						<div>
						Considered the most popular option, Vinyl siding is low-maintenance and adapts to temperatures very well. It becomes an ideal choice for property-owners for fluctuating climatic conditions. It is installed with a secure locking system and nails, thus making it flexible and durable for several years ahead.
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Wood Siding</h6>
						<div>
						This type of siding offers a very warm and rustic appearance to your property. While this one requires more maintenance, it looks richer and classier- adding to the natural aesthetic and making it a timeless and classic beauty.
						</div>

						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Fiber Cement Siding</h6>
						<div>
						This is a great siding service choice simply due to its strength and fire resistance. It is a durable choice, particularly for areas that are prone to wildfires. It offers both style, as well as resilience with natural wooden finish. 
						</div>

						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Insulated Siding</h6>
						<div>
						Enhancing exterior beauty, while also reducing energy costs by up to 20%, Insulated siding is a smart choice for energy efficiency and deriving long-term value. 
						</div>

						<h4 className="section-title mt-30">Ready to Get Started?<span>.</span></h4>
						<div>
						Looking for the best for siding in Edmonton. Let’s bring your vision to life with a solution that stands out for you!
						<br/><br/>
						<b>Request a quote for your project! Contact our experts now! </b>

						</div>

					</div>
				</div>
				</div>
			</div>
        }
}

export default Siding