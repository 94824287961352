import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { PostNoAuthRequest, addBodyClass,removeBodyClass } from '../../utills/common';


const ContactForm = () => {
	const [isSaved, setisSaved] = useState(false);
    const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
    const [resError, setResError] = useState({});
    // const [ContactDetail, setContactDetail] = useState("");
    // const [ContactBanner, setContactBanner] = useState("");
    // const [loading, setLoading] = useState(true)
    const [btnTxt, setBtnTxt] = useState('Send Message')

	const handleContact = async (data) => {
		try { 
		  //  e.preventDefault();
	  
		  setBtnTxt('Sending...');
		  let Res = await PostNoAuthRequest('contact/store',{name:data.name, email:data.email, mobile:data.mobile, address:data.address, description:data.description });
	
		  if(Res.success){
			setisSaved(true);
			reset({name:'', email:'', mobile:'', city:'', description:'' })
			setBtnTxt('Send Message');
		  }else{
			if(Res.data.email!=undefined){
				// errors.email = Res.data.email[0]
				  setResError({email:Res.data.email[0]})
			  }
			  if(Res.data.password!=undefined){
				  setResError({password:Res.data.password[0]})
			  }
			  if(Res.data.error){
				  setResError({error:Res.data.error})
			  } 
			  setBtnTxt('Send Message');
			}
	
			setTimeout(() => {
			  setisSaved(false);
			}, 5000);    
			
		} catch (error) {
		  console.error(error.message);
		}
		};
	

	return (
        <>      
			<div className="ltn__contact-message-area pt-110--- pb-90"> 
					<div className="container">
					<div className="row">
						<div className="col-lg-12">
						<div className="ltn__form-box contact-form-box box-shadow white-bg">
							<h4 className="title-2">Get A Quote</h4>
							<form className="row" onSubmit={handleSubmit(handleContact)} >
							<div className="row">
								<div className="col-md-6">
								<div className="input-item input-item-name ltn__custom-icon">
									<input type="text" name="name" placeholder="Enter your name" {...register("name", { required: 'Field is required' })} />
									{errors.name && <span className="invalid" >Name is required.</span>}
								</div>
								</div>
								<div className="col-md-6">
								<div className="input-item input-item-email ltn__custom-icon">
									<input type="email" className="cs-form_field" placeholder="Enter email address" {...register("email", { 	required: true, pattern: {
										value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
										message: 'Invalid email address',
										}, })} />

										{errors.email?.type==="required" && <span className="invalid" >Email is required.</span>}
										{errors.email?.type === "pattern" && (
											<span className="invalid" >Invalid email</span>
										)}
								</div>
								</div>
								
								<div className="col-md-6">
									<div className="input-item input-item-phone ltn__custom-icon">
										<input type="text" name="address" placeholder="Address" {...register("address")}   />
									</div>
								</div>
								<div className="col-md-6">
									<div className="input-item input-item-phone ltn__custom-icon">
										<input type="text" name="mobile" placeholder="Enter phone number" {...register("mobile", { required: true,
                                                              //  pattern: {
                                                              //     value: /^[0-9]{0,9}$/,
                                                              //     message: 'Invalid email address',
                                                              // } 
                                                              })}  />									
                      						{errors.mobile?.type === "required" && <span className="invalid" >Mobile is required.</span>}
									</div>
								</div>
							</div>
							<div className="input-item input-item-textarea ltn__custom-icon">
								<textarea name="description" placeholder="Enter message" {...register("description")} cols="30"
                        		rows="7" />
							</div>
							{/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p> */}
							<div className="btn-wrapper mt-0">								
								<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
								<span>{btnTxt}</span>
								{/* <Icon icon="bi:arrow-right" /> */}
							</button>
							</div>							
								{ (isSaved)?
									// <p className="form-messege mb-0 mt-20" />
									(<div className="col-sm-12">
										<span>Contact Send Successfully.</span>
									</div>) : "" 
								}
							</form>
						</div>
						</div>
					</div>
					</div>
				</div>
		</>
    )
}

export default ContactForm