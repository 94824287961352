import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const Team_Details = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Agent Details" />
        <TeamDetails />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Team_Details

