import React from 'react';
import { Link } from 'react-router-dom';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footermain';
const PrivacyPolicy = () => {    
    let publicUrl = process.env.PUBLIC_URL+'/'
    return<>
        <NavbarMain />
        <PageHeader headertitle="Privacy Policy" subheader="Privacy Policy" />
        <div className="ltn__team-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
            <div className="ltn__team-details-member-info-details">
                Privacy Policy
            </div>
            </div>
        </div>
        </div>
    </div>
    
    <Footer />
    </>
    
}

export default PrivacyPolicy

