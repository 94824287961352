import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import Footer from './global-components/footermain';
import CarrierForm from './section-components/carrier-form-main';

const Carrier = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Carrier" subheader="Carrier" />
        <CarrierForm />
        <Footer />
    </div>
}

export default Carrier

