import React from 'react';
// import Navbar from './global-components/navbar';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import AboutV5 from './section-components/about-v5';
import Siding from './section-components/siding';
import Footer from './global-components/footermain';

const Siding_main = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Malwa Siding Ltd. - Premier siding service in Edmonton" subheader="Service" />
        {/* <AboutV5 /> */}
        <Siding />
        <Footer />
    </div>
}
export default Siding_main

