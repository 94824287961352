import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Roofing extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">					
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Exceptional and best quality roofing services in Edmonton</h6>
							<h1 className="section-title">Quality, Style, and Durability Do not go out of style with Edmonton's Trusted Roofing Experts<span>.</span></h1>
							<p>Offering the highest quality and the most satisfactory level of protection to your home or business, our roofing services are he ones where quality meets reliability, and style joins hands with durability. At Malwa Siding Ltd., we understand that a roof is not just a structural necessity, but an essential, functional, and aesthetic integrity to any property. Based in Edmonton, we are trusted to be a preferred choice for roofing repair near you, delivering great solutions in roofing replacement and roofing repair.  </p>
							</div>
							
							{/* <div className="btn-wrapper animated">
							<Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
							</div> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl+"assets/img/others/man-working-roof-with-hammer-full-shot.jpg"} alt="Roofing Image" />
						</div>
					</div>
					<div className="col-lg-12 align-self-center">
						<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"Why Choose Us for Your Roofing Needs?" </p>
						</div>
						<div>Imagine that you’re planning to make your dream home for the first time, or your organization needs a complete architectural overhaul. You need a high level of craftsmanship, dedication, and quality. The first thing that comes to your mind is the roof. It is as essential a structural necessity as the foundation of a home. What do you do?
						You either seek references from friends or check out the Internet suggestions. After researching well, you’re here-  Malwa Siding Ltd.’s Roofing Services. We bring you dedication and workmanship accompanied with technique that sets us apart.  Every single project we work on is a commitment to client satisfaction and top quality. We do not believe in making roofing in Edmonton a cumbersome or stressful process. With Malwa Siding Ltd., we make it straightforward, simple, and a personalized experience that meets your specific needs. </div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Our Roofing Services: A Holistic Profile</h6>
						<div>
						Built around precision and versatility, our roofing services ensure that your project stands up to the elements and complements your entire architectural style. Whether you are looking for a faster roofing repair or a full roofing replacement, our team of experts and installation professionals are ready and committed to delivering what they promise. 

						</div>
						<h4 className="section-title mt-30">Roofing Repair Services<span>.</span></h4>
						<div>
						Heavy rains, intense sunlight, and Edmonton's varying climate- all of it takes a toll on the roof. It is this part of the entire project that endures the most. Hence, we offer roofing repair services that address your most pressing pain points and concerns. Rom minor leaks to major roof damages caused by aging or storms, our repairing methods include high-quality materials and precise techniques that restore the resilience and functionality of your roof. 
						Searching for <i>roofing repair near me</i>, look no further! We are here to help you with prompt and reliable service solutions- not compromising one bit on the quality of our work. Our repairing process is created to minimize disruption, while also keeping you updated on your project every step of the way. 
						</div>

						<h4 className="section-title mt-30">Roofing Replacement<span>.</span></h4>
						<div>
						The roof reaches its age at some point in time. The serviceable life of a roof is not here to stay forever. Hence, when it’s time you must gear up for the major overhauling experience of your property. By opting for the roofing replacement service you get a fresh start to your entire project, while also staying updated and relevant to the present time. We offer roofing replacement services that begin from the tear-off and go to the final installation stage. We handle each stage of roofing replacement meticulously. By making use of top-of-the-line materials, we imbibe it with advanced techniques to ensure that your project withstands every aspect of Edmonton’s weather- from harsh winters to intense summers. <br/><br/>
						If you’re looking for a roofing replacement near me, and want a solution standing the test of time, we work at the closest locality near you. From a range of materials, you can carefully choose to ensure your roof’s insulation, aesthetic appeal, and durability. 
						</div>

						<h4 className="section-title mt-30">Emergency Roofing Repair<span>.</span></h4>
						<div>
						We understand that not every project can be planned. Some of the roofing issues may require immediate attention and emergency roofing repair. A storm damage or sudden leak can escalate problems and further damage the property. This is where we step in. Our emergency roofing repair services are available at the call of a button. We are available on short notice with our skilled experts to address urgent issues with speed and efficiency. No matter what the scope of your roofing problem is, we are here to fill in the missing gaps and give you the much-needed peace of mind.  
						</div>

						<h4 className="section-title mt-30">What to Expect From Us- One-stop for roofing Edmonton solutions <span>.</span></h4>
						
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Consultation and Inspection</h6>
						<div>
						Beginning with an in-depth inspection, we evaluate the current condition of your roof and offer quick fixes. If you’re searching for the roofing replacement near me or anywhere else in Edmonton, we provide you with complete consultation and inspection. 
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Material Selection and Planning</h6>
						<div>
						Once we have completed our inspection and have talked with you, we will work with you to understand your budget and design roofing solutions that fit your vision.
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Expert Installation or Repair</h6>
						<div>
						Our experienced team of experts executes each task with utmost precision and with least disruption. Our roofing Edmonton solutions are taken care of by expert professionals who understand the importance of dedication and detail.
						</div>
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">Final Walkthrough and Guarantee</h6>
						<div>
						After we have completed our repairing or installation service, we conduct a final walkthrough of the project. Our work doesn’t just end here; we stand by the quality of our services and offer guarantees on all our roofing projects to give you peace of mind.
						</div>

						<h6 className="section-title mt-30">Contact Us Today <span>.</span></h6>
						<div>
						Are you ready to take that step to enhance your property or protect it? Contact us at Malwa Siding Ltd. to schedule your consultation. <br/> <br/>
						Request a quote for your complete roofing replacement or a roofing repair project. Edmonton’s first choice of reliable roofing solutions- Malwa Siding Ltd. 
						<br/> <br/>
							Get in touch with us today! 

						</div>


					</div>
				</div>
				</div>
			</div>
        }
}

export default Roofing