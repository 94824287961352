import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const BlogDetailsPage = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="News Details" />
        <BlogDetails />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BlogDetailsPage

