import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Quality Materials</h6>
			          <h1 className="section-title">Quality Materials and Expert Craftsmanship</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
			      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon"><i className="flaticon-car" /></span>
			            <span className="category-number">01</span>
			            <span className="category-title">Vinyl Siding</span>
			            <span className="category-brief">
						Considered the most popular option, Vinyl siding is low-maintenance and adapts to temperatures very well. It becomes an ideal choice for property-owners for fluctuating climatic conditions. It is installed with a secure locking system.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon"><i className="flaticon-swimming" /></span>
			            <span className="category-number">02</span>
			            <span className="category-title">Wood Siding</span>
			            <span className="category-brief">
						This type of siding offers a very warm and rustic appearance to your property. While this one requires more maintenance, it looks richer and classier- adding to the natural aesthetic and making it a timeless and classic beauty for several years ahead. 
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon"><i className="flaticon-secure-shield" /></span>
			            <span className="category-number">03</span>
			            <span className="category-title">Fiber Cement Siding</span>
			            <span className="category-brief">
						This is a great siding service choice simply due to its strength and fire resistance. It is a durable choice, particularly for areas that are prone to wildfires. It offers both style, as well as resilience with natural wooden finish.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      {/* <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon"><i className="flaticon-stethoscope" /></span>
			            <span className="category-number">04</span>
			            <span className="category-title">Insulated Siding</span>
			            <span className="category-brief">
						Enhancing exterior beauty, while also reducing energy costs by up to 20%, Insulated siding is a smart choice for energy efficiency and deriving long-term value.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div> */}
			     
			    </div>
			  </div>
			</div>

        }
}

export default CategoryV2