import React, { useState }from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';


	const NavbarMain = () => {
        let publicUrl = process.env.PUBLIC_URL+'/'
		const [active, setActive] = useState(null);
		
		const navigate = (id) => {
			console.log(id);
			setActive(id);
		  };
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
				<div className="row">
					<div className="col-md-9">
					<div className="ltn__top-bar-menu">
						<ul>
						<li><a href="mailto:info@malwasidingedmonton.com?Subject=A%20new%20query%20greetings%20from%20user%20on%20malwasidingedmonton" className='heading_row'><i className="icon-mail" />info@malwasidingedmonton.com</a></li>
						<li><a href="javascript::void(0)" className='heading_row'><i className="icon-placeholder" />9405 Pear Crescent SW Edmonton</a></li>
						<li><a href="callto:7808858400" className='heading_row'><i className="fas fa-phone" />7808-858-400</a></li>
						</ul>
					</div>
					</div>
					<div className="col-md-3">
					<div className="top-bar-right text-end">
						<div className="ltn__top-bar-menu">
						<ul>
							{/* <li className="d-none">
							<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
								<ul>
								<li><a href="#" className="dropdown-toggle"><span className="active-currency">English</span></a>
									<ul>
									<li><Link to="#">Arabic</Link></li>
									<li><Link to="#">Bengali</Link></li>
									<li><Link to="#">Chinese</Link></li>
									<li><Link to="#">English</Link></li>
									<li><Link to="#">French</Link></li>
									<li><Link to="#">Hindi</Link></li>
									</ul>
								</li>
								</ul>
							</div>
							</li> */}
							<li>
								<Social />
							</li>
							<li>
							{/* header-top-btn */}
							{/* <div className="header-top-btn">
								<Link to="/add-listing">Add Listing</Link>
							</div> */}
							</li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
				<div className="container">
				<div className="row">
					<div className="col">
					<div className="site-logo-wrap">
						<div className="site-logo go-top">
						<Link to="/"><img className='logo_style' src={publicUrl+"assets/img/logo.svg"} alt="Logo" /></Link>
						</div>
						<div className="get-support clearfix d-none">
						<div className="get-support-icon">
							<i className="icon-call" />
						</div>
						<div className="get-support-info">
							<h6>Get Support</h6>
							<h4><a href="tel:+123456789">123-456-789-10</a></h4>
						</div>
						</div>
					</div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							{/* isActive={active === item.id} onClick={navigate} */}
								<li><Link onClick={() => navigate('home')} className={ (active=='home') ? "active" : ""}  to="/"  >Home</Link></li>
								<li><Link to="/about" onClick={() => navigate("about")} className={ (active=="about") ? "active" : ""}  >About</Link></li>
								<li ><Link to="#" >Services</Link>
									<ul>
										<li><Link to="/siding" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Siding</Link></li>
										<li><Link to="/roofing" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Roofing</Link></li>
										<li><Link to="/concrete" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Concrete</Link></li>										
									</ul>
								</li>
								
								{/* <li><Link to="/service-details">Service Details</Link></li> */}
								{/* <li><Link to="/portfolio">Portfolio</Link></li> */}
								{/* <li><Link to="/portfolio-details">Portfolio Details</Link></li> */}
								{/* <li><Link to="/team" onClick={() => navigate("team")} className={ (active=="team") ? "active" : ""}>Team</Link></li> */}
								{/* <li><Link to="/blog">News</Link></li>
								<li><Link to="/blog-grid">News Grid</Link></li>
								<li><Link to="/blog-details">News details</Link></li> */}
								<li><Link to="/contact" onClick={() => navigate("contact")} className={ (active=="contact") ? "active" : ""} >Contact</Link></li>
								<li><Link to="/carrier" onClick={() => navigate("carrier")} className={ (active=="carrier") ? "active" : ""}  >Carrier</Link></li>
							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
					{/* header-search-1 */}
					<div className="header-search-wrap">
						{/* <div className="header-search-1">
						<div className="search-icon">
							<i className="icon-search for-search-show" />
							<i className="icon-cancel  for-search-close" />
						</div>
						</div> */}
						{/* <div className="header-search-1-form">
						<form id="#" method="get" action="#">
							<input type="text" name="search" defaultValue placeholder="Search here..." />
							<button type="submit">
							<span><i className="icon-search" /></span>
							</button>
						</form>
						</div> */}
					</div>
					{/* user-menu */}
					{/* <div className="ltn__drop-menu user-menu">
						<ul>
						<li>
							<Link to="#"><i className="icon-user" /></Link>
							<ul className="go-top">
							<li><Link to="/login">Sign in</Link></li>
							<li><Link to="/register">Register</Link></li>
							<li><Link to="/my-account">My Account</Link></li>
							</ul>
						</li>
						</ul>
					</div> */}
					{/* mini-cart */}
					{/* <div className="mini-cart-icon">
                            <a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
                                <i className="icon-shopping-cart"></i>
                                <sup>2</sup>
                            </a>
                    </div> */}
					{/* mini-cart */}
					{/* Mobile Menu Button */}
					<div className="mobile-menu-toggle d-xl-none">
						<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
						<svg viewBox="0 0 800 600">
							<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
							<path d="M300,320 L540,320" id="middle" />
							<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
						</svg>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/logo.svg"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					{/* <div className="ltn__utilize-menu-search-form">
					<form action={"#"}>
						<input type="text" placeholder="Search..." />
						<button><i className="fas fa-search" /></button>
					</form>
					</div> */}
					<div className="ltn__utilize-menu">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li><Link to="/about">About</Link></li>
						<li ><Link to="#" >Services</Link>
							<ul className="sub-menu">
								<li><Link to="/siding" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Siding</Link></li>
								<li><Link to="/roofing" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Roofing</Link></li>
								<li><Link to="/concrete" onClick={() => navigate("service")} className={ (active=="service") ? "active" : ""} >Concrete</Link></li>										
							</ul>
						</li>
						{/* <li><Link to="/service-details">Service Details</Link></li> */}
						{/* <li><Link to="/portfolio">Portfolio</Link></li> */}
						{/* <li><Link to="/portfolio-details">Portfolio Details</Link></li> */}
						{/* <li><Link to="/team">Team</Link></li> */}
						{/* <li><Link to="/blog">News</Link></li>
						<li><Link to="/blog-grid">News Grid</Link></li>
						<li><Link to="/blog-details">News details</Link></li> */}
						<li><Link to="/contact">Contact</Link></li>
						<li><Link to="/carrier">Carrier</Link></li>
					</ul>
					
					</div>					
					<div className="ltn__social-media-2">
					<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div>
				</div>
			</div>

		</div>
        )
}


export default NavbarMain