import React from 'react';
import NavbarMain from './global-components/navbarmain';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form-main';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footermain';

const ContactV1 = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        {/* <Map /> */}
        <Footer />
    </div>
}

export default ContactV1

